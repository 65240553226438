import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss']
})
export class FooterNavComponent implements OnInit {

  twitter = environment.twitter
  facebook = environment.facebook
  insta = environment.instagram

  constructor() { }

  @HostListener("document:mousemove", ["$event"])

  ngOnInit(): void {
  }

}
